<template>
  <ion-fab
    v-if="
      whappyClientConfig.name == 'Trivellato' ||
      whappyClientConfig.name == 'Automotive SpA'
    "
    vertical="top"
    horizontal="end"
  >
    <ion-fab-button
      @click="openModalNewKpiChat"
      class="ion-margin-end ion-margin-bottom"
    >
      <ion-icon :icon="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <campaign-layout
    :showMenu="computed_debug"
    :showBack="computed_debug"
    :pageTitle="$t('pageTitle.Menu')"
  >
    <common-campaign-menu></common-campaign-menu>
  </campaign-layout>
</template>

<script>
import { add } from "ionicons/icons";
import ApiService from "../../common/service.api";
import { translate } from "../../common/helper.auth";
import CampaignLayout from "../../components/CampaignLayout";
import { useRoute } from "vue-router";
import CommonCampaignMenu from "../../components/CampaignMenu";
import UserService from "../../common/mixins/User";
import ModalNewOrdine from "@/views/Modals/ModalNewOrdine.vue";
import { modalController } from "@ionic/vue";
export default {
  name: "Menu",
  data() {
    return {
      kpis: null,
      showList1: false,
      showList2: false,
      add,
    };
  },
  components: {
    CommonCampaignMenu,
    CampaignLayout,
    ModalNewOrdine,
  },
  setup() {
    const route = useRoute();
    const { id } = route.params;
    return { id };
  },
  created() {},
  ionViewWillEnter() {},
  methods: {
    async openModalNewKpiChat() {
      let options = this.options;

      const modal = await modalController.create({
        component: ModalNewOrdine,
        cssClass: "common-modal",
      });

      modal.onDidDismiss().then(async () => {
        await ApiService.get(`SalesChat/GetOrders`).then((res) => {
          this.items = [];
          res.data.forEach((element) => {
            this.items.push(element);
          });
        });
      });
      return modal.present();
    },
  },
  computed: {
    computed_debug() {
      if (this.$cookies.get("debug") == 1) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
